import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { CheckCircle2, ArrowRight, AlertTriangle } from '@combateafraude/icons/general';
import { Row, Col } from 'antd';
import classNames from 'classnames';

import { toFormatDate } from 'utils/formatters';

import Card from 'components/Card';
import Button from 'components/Button';
import QueueFiltersModal from './components/QueueFiltersModal';

import './styles.less';

const QueueCards = ({ data, totals, loadingTable, query, setFilterData }) => {
  const { openModal: openModalQueueFilters, Modal: ModalQueueFilters } =
    QueueFiltersModal({ query, setFilterData });

  const handleOpenModal = useCallback(async () => {
    openModalQueueFilters();
  }, [openModalQueueFilters]);

  return (
    <div id="queue-cards-component">
      {ModalQueueFilters}
      <Row>
        <Col span={8}>
          <Card
            title="Defina os filtros"
            extra={
              <Button type="link" onClick={handleOpenModal} disabled={loadingTable}>
                <ArrowRight width={24} height={24} />
              </Button>
            }
            loading={loadingTable}
            className="filters-card"
          >
            <div
              className={`card-row flex space-between ${classNames({
                'filter-is-enabled': query?._recordTypes?.length > 0,
              })}`}
            >
              <span>Tipo de registro</span>
              {query?._recordTypes?.length > 0 && <CheckCircle2 width={20} height={20} />}
            </div>

            <div
              className={`card-row flex space-between ${classNames({
                'filter-is-enabled': query?._solutionLevels?.length > 0,
              })}`}
            >
              <span>Tipo de solução na Documentoscopia</span>
              {query?._solutionLevels?.length > 0 && (
                <CheckCircle2 width={20} height={20} />
              )}
            </div>

            <div
              className={`card-row flex space-between ${classNames({
                'filter-is-enabled': query?._operationsGroups?.length > 0,
              })}`}
            >
              <span>Grupo de operações</span>
              {query?._operationsGroups?.length > 0 && (
                <CheckCircle2 width={20} height={20} />
              )}
            </div>
            <div
              className={`card-row flex space-between ${classNames({
                'filter-is-enabled': query?._testEvaluation === 'true',
              })}`}
            >
              <span>Análises de testes</span>
              {query?._testEvaluation === 'true' && (
                <CheckCircle2 width={20} height={20} />
              )}
            </div>
          </Card>
        </Col>

        <Col span={10}>
          <Card title="Tempo de fila" loading={loadingTable}>
            <div className="flex center space-between card-row">
              <div className="flex center">
                <span className="rect-icon">
                  <svg>
                    <rect className="fill-grey" />
                  </svg>
                </span>
                <span>Execuções com + de 5 min na fila</span>
              </div>
              <span>{totals.moreThan5Min}</span>
            </div>
            <div className="flex center space-between card-row">
              <div className="flex center">
                <span className="rect-icon">
                  <svg>
                    <rect className="fill-warning" />
                  </svg>
                </span>
                <span>Execuções com + de 15 min na fila</span>
              </div>
              <span>{totals.moreThan15Min}</span>
            </div>
            <div className="flex center space-between card-row">
              <div className="flex center">
                <span className="rect-icon">
                  <svg>
                    <rect className="fill-danger" />
                  </svg>
                </span>
                <span>Execuções com + de 30 min na fila</span>
              </div>
              <span>{totals.moreThan30Min}</span>
            </div>
            <div className="flex center space-between card-row">
              <div className="flex center dark-red">
                <AlertTriangle />
                <span>Registro mais antigo na fila</span>
              </div>
              <span>{data.length > 0 ? toFormatDate(totals.oldestCreation) : '-'}</span>
            </div>
          </Card>
        </Col>

        <Col span={6}>
          <Card title="Filas" loading={loadingTable}>
            <div className="flex space-between card-row">
              <span>Documentoscopia</span>
              <span>{totals.documentscopy}</span>
            </div>
            <div className="flex space-between card-row">
              <span>Pendente de OCR</span>
              <span>{totals.pendingOcr}</span>
            </div>
            <div className="flex space-between card-row">
              <span>DoubleCheck Liveness</span>
              <span>{totals.doubleCheckLiveness}</span>
            </div>
            <div className="flex space-between card-row">
              <span>Total de itens</span>
              <span>{data.length}</span>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
QueueCards.propTypes = {
  data: PropTypes.arrayOf(PropTypes.objectOf).isRequired,
  totals: PropTypes.objectOf(PropTypes.objectOf).isRequired,
  loadingTable: PropTypes.bool.isRequired,
  query: PropTypes.objectOf(PropTypes.objectOf).isRequired,
  setFilterData: PropTypes.func.isRequired,
};

export default QueueCards;

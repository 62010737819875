import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Form, Row, Col, Input, Select, Tooltip, Tag, Switch } from 'antd';
import classNames from 'classnames';

import {
  Checkmark,
  CircleFalse,
  File,
  CheckCircle2,
} from '@combateafraude/icons/general';

import { useCustomer } from 'hooks/customer';
import { useFetch } from 'services/hooks';

import { toMaskedCnpj } from 'utils/formatters';
import customerStatusList from 'pages/private/Review/ReviewFilter/utils/customerStatusList.json';

import Wrapper from 'pages/private/wrapper';
import Loader from 'components/Loader';
import showToastMessage from 'components/ToastMessage';
import Subheader from './components/Subheader';

import './styles.less';

const { Option } = Select;

const CreateOrUpdateOperationsGroup = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const { id: operationsGroupId } = useParams();

  const [loading, setLoading] = useState(true);

  const [hasChangedOperationsGroup, setHasChangedOperationsGroup] = useState(false);

  const [searchCustomer, setSearchCustomer] = useState(null);
  const [allCustomers, setAllCustomers] = useState([]);
  const [customersSearched, setCustomersSearched] = useState([]);

  const [isDocumentscopySelected, setIsDocumentscopySelected] = useState(false);
  const [disableCustomers, setDisableCustomers] = useState(false);
  const [disableTestCustomers, setDisableTestCustomers] = useState(false);

  const { getCustomers, customersData: customers, loadingCustomers } = useCustomer();

  const { get: getOperationsGroup, data: operationsGroupData } = useFetch();
  const {
    put: putOperationsGroup,
    post: postOperationsGroup,
    loading: loadingUpdate,
  } = useFetch();

  const cnpjFormattedToSearch = useCallback((cnpj) => {
    if (!cnpj) return undefined;

    const cnpjFormatted = cnpj
      ?.replaceAll('.', '')
      ?.replaceAll('/', '')
      ?.replaceAll('-', '');

    return cnpjFormatted;
  }, []);

  const onCustomerSearch = useCallback(
    (searchTerm) => {
      if (searchTerm === '') {
        setCustomersSearched(allCustomers);
      }

      const customersFiltered = allCustomers?.filter((customer) => {
        const cnpjFormatted = cnpjFormattedToSearch(customer?.cnpj);

        if (
          customer?.fantasyName?.toUpperCase().includes(searchTerm?.toUpperCase()) ||
          customer?.companyName?.toUpperCase().includes(searchTerm?.toUpperCase()) ||
          cnpjFormatted?.includes(searchTerm)
        ) {
          return customer;
        }

        return false;
      });

      setCustomersSearched(customersFiltered || []);
    },
    [allCustomers, cnpjFormattedToSearch]
  );

  const handleFinish = useCallback(
    async (payload) => {
      try {
        if (payload?.documentType?.includes('others')) {
          payload.documentType.push('outros');
        }

        if (operationsGroupId) {
          await putOperationsGroup({
            url: `${process.env.REACT_APP_BASE_URL_WECHECK_API}/operations-groups/${operationsGroupId}`,
            payload,
          });

          showToastMessage({
            type: 'success',
            text: 'Grupo de operações alterado com sucesso!',
          });
        } else {
          await postOperationsGroup({
            url: `${process.env.REACT_APP_BASE_URL_WECHECK_API}/operations-groups`,
            payload,
          });
          showToastMessage({
            type: 'success',
            text: 'Grupo de operações criado com sucesso!',
          });
        }

        history.push('/operations-groups');
      } catch (error) {
        const createOrEdit = !operationsGroupId ? 'criar' : 'editar';
        showToastMessage({
          type: 'error',
          text: `Houve um problema ao ${createOrEdit} este grupo de operações.`,
        });
      }
    },
    [operationsGroupId, history, putOperationsGroup, postOperationsGroup]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    await getCustomers();

    if (!operationsGroupId) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const customersFiltered = customers?.docs?.filter(
      (customer) => customer?.fantasyName || customer?.companyName
    );

    setAllCustomers(customersFiltered || []);
    setCustomersSearched(customersFiltered || []);
  }, [customers]);

  const onChangeServices = useCallback(
    (services) => {
      if (services?.includes('documentscopy')) {
        setIsDocumentscopySelected(true);
      } else {
        setIsDocumentscopySelected(false);
        form.setFieldsValue({ solutionLevel: [] });
      }
    },
    [form]
  );

  const setFormValues = useCallback(
    (data) => {
      if (!data) return;

      const formValues = {
        name: data?.name,
        services: data?.services,
        solutionLevel: data?.solutionLevel || undefined,
        customers: data?.customers || undefined,
        testCustomers: data?.testCustomers || undefined,
        documentType: data?.documentType || undefined,
        states: data?.states || undefined,
        masterGroup: data?.masterGroup,
      };

      onChangeServices(formValues.services);

      form.setFieldsValue(formValues);
    },
    [onChangeServices, form]
  );

  const fetchOperationsGroup = useCallback(async () => {
    try {
      const response = await getOperationsGroup({
        url: `${process.env.REACT_APP_BASE_URL_WECHECK_API}/operations-groups/${operationsGroupId}`,
        config: {},
      });

      if (response?.docs?.documentType?.findIndex((type) => type === 'outros')) {
        response.docs.documentType = response.docs.documentType.filter(
          (type) => type !== 'outros'
        );
      }

      if (response?.docs?.customers?.length > 0) setDisableTestCustomers(true);
      if (response?.docs?.testCustomers?.length > 0) setDisableCustomers(true);

      setFormValues(response?.docs);
    } catch (error) {
      const createOrEdit = !operationsGroupId ? 'criação' : 'alteração';
      showToastMessage({
        type: 'error',
        text: `Houve um problema ao obter dados para ${createOrEdit} do grupo de operações.`,
      });

      history.push('/operations-groups');
    }

    setLoading(false);
  }, [getOperationsGroup, operationsGroupId, setFormValues, history]);

  const firstLoad = useRef(true);
  useEffect(() => {
    if (!firstLoad.current) return;
    firstLoad.current = false;
    if (operationsGroupId && !operationsGroupData) {
      fetchOperationsGroup();
    }
  }, [fetchOperationsGroup, operationsGroupData, operationsGroupId]);

  const customersUpdateHandler = useCallback(() => {
    setHasChangedOperationsGroup(true);

    setDisableCustomers(form.getFieldValue('testCustomers')?.length > 0);
    setDisableTestCustomers(form.getFieldValue('customers')?.length > 0);
  }, [form]);

  return (
    <Wrapper
      subheader={
        <Subheader
          title={`${!operationsGroupId ? 'Criar' : 'Editar'} grupo de operações`}
          loading={loading || loadingUpdate}
          formRef={form}
          disabledSaveButton={!hasChangedOperationsGroup}
        />
      }
    >
      <Form layout="vertical" form={form} onFinish={handleFinish}>
        {loading ? (
          <Loader className="flex center mrg-top-50" />
        ) : (
          <div>
            <Row>
              <Col span={12}>
                <Form.Item
                  label="Nome do grupo de operações"
                  name="name"
                  rules={[{ required: true }]}
                >
                  <Input
                    autoComplete="off"
                    onChange={() => setHasChangedOperationsGroup(true)}
                    disabled={loadingUpdate}
                    className="ant-input-text"
                  />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item
                  label="Grupo mestre"
                  name="masterGroup"
                  valuePropName="checked"
                >
                  <Switch
                    disabled={loadingUpdate}
                    onChange={() => setHasChangedOperationsGroup(true)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  label="Tipo de serviço"
                  name="services"
                  rules={[{ required: true }]}
                >
                  <Select
                    mode="multiple"
                    optionLabelProp="label"
                    showSearch
                    className="multi-select-primary"
                    removeIcon={<CircleFalse />}
                    menuItemSelectedIcon={<Checkmark width={20} height={20} />}
                    onChange={(services) => {
                      onChangeServices(services);
                      setHasChangedOperationsGroup(true);
                    }}
                    disabled={loadingUpdate}
                  >
                    <Option key="documentscopy" label="Documentoscopia">
                      Documentoscopia
                    </Option>
                    <Option key="pending-ocr" label="Pendente OCR">
                      Pendente OCR
                    </Option>
                    <Option key="double-check-liveness" label="Double-check de Liveness">
                      Double-check de Liveness
                    </Option>
                    <Option key="cleaner" label="Cleaner">
                      Cleaner
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Tipo de solução de documentoscopia (deixe vazio para todos)"
                  name="solutionLevel"
                  className={`${classNames({
                    'show-component': isDocumentscopySelected,
                  })} hide-component`}
                >
                  <Select
                    mode="multiple"
                    optionLabelProp="label"
                    showSearch
                    listHeight={280}
                    className="multi-select-primary"
                    removeIcon={<CircleFalse />}
                    menuItemSelectedIcon={<Checkmark width={20} height={20} />}
                    onChange={() => setHasChangedOperationsGroup(true)}
                    disabled={loadingUpdate}
                  >
                    <Option key="standard" label="Fila Standard">
                      Fila Standard
                    </Option>
                    <Option key="advanced" label="Fila Advanced">
                      Fila Advanced
                    </Option>
                    <Option key="international" label="Fila Internacional">
                      Fila Internacional
                    </Option>
                    <Option key="qrcode" label="Fila QRCode">
                      Fila QRCode
                    </Option>
                    <Option key="exclusive" label="Fila Exclusive">
                      Fila Exclusive
                    </Option>
                    <Option key="premium" label="Fila premium">
                      Fila Premium
                    </Option>
                    <Option key="poc" label="Fila POC">
                      Fila POC
                    </Option>
                    <Option key="quality" label="Fila Quality">
                      Fila Quality
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={16}>
                <Form.Item
                  label="Selecione clientes para esse grupo (deixe vazio para todos)"
                  name="customers"
                >
                  <Select
                    mode="multiple"
                    showSearch
                    className="multi-select-primary"
                    optionLabelProp="label"
                    dropdownClassName="multiselect-filters"
                    filterOption={false}
                    listHeight={320}
                    dropdownAlign={{ offset: [0, 2] }}
                    removeIcon={<CircleFalse />}
                    menuItemSelectedIcon={<Checkmark width={20} height={20} />}
                    disabled={loadingCustomers || loadingUpdate || disableCustomers}
                    loading={loadingCustomers}
                    notFoundContent={
                      !loadingCustomers && !customers?.docs?.length ? (
                        <span>
                          {searchCustomer
                            ? `Nenhum resultado encontrado para "${searchCustomer}"`
                            : 'Busque clientes por nome ou e-mail'}
                        </span>
                      ) : null
                    }
                    onSearch={onCustomerSearch}
                    onClear={() => setCustomersSearched(allCustomers)}
                    onBlur={() => {
                      setSearchCustomer(null);
                      setCustomersSearched(allCustomers);
                    }}
                    onChange={customersUpdateHandler}
                  >
                    {customersSearched?.map((c) => {
                      if (c.wecheckTestEvaluation) return <></>;
                      return (
                        <Option
                          key={c._id}
                          label={c.fantasyName || c.companyName}
                          value={c.tenantId}
                        >
                          <div className="multiselect-option">
                            <span className="option-title">
                              {c.fantasyName || c.companyName}
                            </span>
                            <div className="flex start-center">
                              <span className="option-subtitle">
                                {toMaskedCnpj(c.cnpj)}
                              </span>
                              <section className="mrg-left-10">
                                <>
                                  {c?.verified && (
                                    <Tooltip title="Em produção">
                                      <CheckCircle2
                                        className="gx-text-success"
                                        width={16}
                                        height={16}
                                      />
                                    </Tooltip>
                                  )}
                                  {c?.hasContract && (
                                    <Tooltip title="Possui contrato">
                                      <File />
                                    </Tooltip>
                                  )}
                                  {c?.status && (
                                    <Tag
                                      className="mrg-left-5 font-size-10"
                                      color={
                                        customerStatusList[c?.status || 'lead'].color
                                      }
                                    >
                                      {c?.status === 'internal-customer'
                                        ? 'Interno'
                                        : customerStatusList[c?.status || 'lead'].name}
                                    </Tag>
                                  )}
                                </>
                              </section>
                            </div>
                          </div>
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={16}>
                <Form.Item
                  label="Selecione clientes de teste para esse grupo"
                  name="testCustomers"
                >
                  <Select
                    mode="multiple"
                    showSearch
                    className="multi-select-primary"
                    optionLabelProp="label"
                    dropdownClassName="multiselect-filters"
                    filterOption={false}
                    listHeight={320}
                    dropdownAlign={{ offset: [0, 2] }}
                    removeIcon={<CircleFalse />}
                    menuItemSelectedIcon={<Checkmark width={20} height={20} />}
                    disabled={loadingCustomers || loadingUpdate || disableTestCustomers}
                    loading={loadingCustomers}
                    notFoundContent={
                      !loadingCustomers && !customers?.docs?.length ? (
                        <span>
                          {searchCustomer
                            ? `Nenhum resultado encontrado para "${searchCustomer}"`
                            : 'Busque clientes por nome ou e-mail'}
                        </span>
                      ) : null
                    }
                    onSearch={onCustomerSearch}
                    onClear={() => setCustomersSearched(allCustomers)}
                    onBlur={() => {
                      setSearchCustomer(null);
                      setCustomersSearched(allCustomers);
                    }}
                    onChange={customersUpdateHandler}
                  >
                    {customersSearched?.map((c) => {
                      if (!c.wecheckTestEvaluation) return <></>;
                      return (
                        <Option
                          key={c._id}
                          label={c.fantasyName || c.companyName}
                          value={c.tenantId}
                        >
                          <div className="multiselect-option">
                            <span className="option-title">
                              {c.fantasyName || c.companyName}
                            </span>
                            <div className="flex start-center">
                              <span className="option-subtitle">
                                {toMaskedCnpj(c.cnpj)}
                              </span>
                              <section className="mrg-left-10">
                                <>
                                  {c?.verified && (
                                    <Tooltip title="Em produção">
                                      <CheckCircle2
                                        className="gx-text-success"
                                        width={16}
                                        height={16}
                                      />
                                    </Tooltip>
                                  )}
                                  {c?.hasContract && (
                                    <Tooltip title="Possui contrato">
                                      <File />
                                    </Tooltip>
                                  )}
                                  {c?.status && (
                                    <Tag
                                      className="mrg-left-5 font-size-10"
                                      color={
                                        customerStatusList[c?.status || 'lead'].color
                                      }
                                    >
                                      {c?.status === 'internal-customer'
                                        ? 'Interno'
                                        : customerStatusList[c?.status || 'lead'].name}
                                    </Tag>
                                  )}
                                </>
                              </section>
                            </div>
                          </div>
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={16}>
                <Form.Item
                  label="Que tipo de Documento esse grupo vai fazer (deixe vazio para todos)"
                  name="documentType"
                >
                  <Select
                    mode="multiple"
                    optionLabelProp="label"
                    showSearch
                    listHeight={184}
                    className="multi-select-primary"
                    removeIcon={<CircleFalse />}
                    menuItemSelectedIcon={<Checkmark width={20} height={20} />}
                    onChange={() => setHasChangedOperationsGroup(true)}
                    disabled={loadingUpdate}
                  >
                    <Option key="rg" label="RG">
                      RG
                    </Option>
                    <Option key="cnh" label="CNH">
                      CNH
                    </Option>
                    <Option key="rnm" label="RNM">
                      RNM
                    </Option>
                    <Option key="rne" label="RNE">
                      RNE
                    </Option>
                    <Option key="others" label="OUTROS">
                      OUTROS
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={16}>
                <Form.Item
                  label="Quais UF esse grupo vai receber? (deixe vazio para todos)"
                  name="states"
                >
                  <Select
                    mode="multiple"
                    optionLabelProp="label"
                    showSearch
                    listHeight={184}
                    className="multi-select-primary"
                    removeIcon={<CircleFalse />}
                    menuItemSelectedIcon={<Checkmark width={20} height={20} />}
                    onChange={() => setHasChangedOperationsGroup(true)}
                    disabled={loadingUpdate}
                  >
                    <Option key="AC" label="AC">
                      AC
                    </Option>
                    <Option key="AL" label="AL">
                      AL
                    </Option>
                    <Option key="AP" label="AP">
                      AP
                    </Option>
                    <Option key="AM" label="AM">
                      AM
                    </Option>
                    <Option key="BA" label="BA">
                      BA
                    </Option>
                    <Option key="CE" label="CE">
                      CE
                    </Option>
                    <Option key="DF" label="DF">
                      DF
                    </Option>
                    <Option key="ES" label="ES">
                      ES
                    </Option>
                    <Option key="GO" label="GO">
                      GO
                    </Option>
                    <Option key="MA" label="MA">
                      MA
                    </Option>
                    <Option key="MT" label="MT">
                      MT
                    </Option>
                    <Option key="MS" label="MS">
                      MS
                    </Option>
                    <Option key="MG" label="MG">
                      MG
                    </Option>
                    <Option key="PA" label="PA">
                      PA
                    </Option>
                    <Option key="PB" label="PB">
                      PB
                    </Option>
                    <Option key="PR" label="PR">
                      PR
                    </Option>
                    <Option key="PE" label="PE">
                      PE
                    </Option>
                    <Option key="PI" label="PI">
                      PI
                    </Option>
                    <Option key="RJ" label="RJ">
                      RJ
                    </Option>
                    <Option key="RN" label="RN">
                      RN
                    </Option>
                    <Option key="RS" label="RS">
                      RS
                    </Option>
                    <Option key="RO" label="RO">
                      RO
                    </Option>
                    <Option key="RR" label="RR">
                      RR
                    </Option>
                    <Option key="SC" label="SC">
                      SC
                    </Option>
                    <Option key="SP" label="SP">
                      SP
                    </Option>
                    <Option key="SE" label="SE">
                      SE
                    </Option>
                    <Option key="TO" label="TO">
                      TO
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </div>
        )}
      </Form>
    </Wrapper>
  );
};

export default CreateOrUpdateOperationsGroup;

import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { Modal as StandardModal, Typography, Row, Col, Checkbox } from 'antd';
import { Button } from '@combateafraude/react';
import _ from 'lodash';
import classNames from 'classnames';

import { useOperationsGroups } from 'hooks/operationsGroups';

import './styles.less';

import SelectCustomFilter from './components/SelectCustomFilter';

const { Title, Text } = Typography;

const QueueFiltersModal = ({ query, setFilterData }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const [isDocumentscopySelected, setIsDocumentscopySelected] = useState(false);

  const [allGroups, setAllGroups] = useState([]);
  const [searchGroup, setSearchGroup] = useState(null);
  const [groupsSearched, setGroupsSearched] = useState([]);

  const {
    getOperationsGroups,
    operationsGroupsData,
    loadingOperationsGroups,
    operationsGroupsError,
  } = useOperationsGroups();

  const [recordTypesFilter, setRecordTypesFilter] = useState(() =>
    query._recordTypes && !_.isEmpty(query._recordTypes)
      ? query._recordTypes?.split(';')?.map((val) => ({ key: val, value: val }))
      : []
  );

  const [solutionLevelsFilter, setSolutionLevelsFilter] = useState(() =>
    query._solutionLevels && !_.isEmpty(query._solutionLevels)
      ? query._solutionLevels?.split(';')?.map((val) => ({ key: val, value: val }))
      : []
  );

  const [groupsFilter, setGroupsFilter] = useState(() =>
    query._operationsGroups && !_.isEmpty(query._operationsGroups)
      ? query._operationsGroups?.split(';')?.map((val) => ({ key: val, value: val }))
      : []
  );

  const [testEvaluation, setTestEvaluation] = useState(query._testEvaluation === 'true');

  const onGroupSearch = useCallback(
    (searchTerm) => {
      if (searchTerm === '') {
        setGroupsSearched(allGroups);
      }

      const groupsFiltered = allGroups?.filter((group) => {
        if (group?.name?.toUpperCase().includes(searchTerm?.toUpperCase())) {
          return group;
        }

        return false;
      });

      setGroupsSearched(groupsFiltered || []);
    },
    [allGroups]
  );

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const openModal = useCallback(async () => {
    setModalVisible(true);

    if (operationsGroupsError) {
      await getOperationsGroups();
    }
  }, [operationsGroupsError, getOperationsGroups]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const applyFilters = useCallback(() => {
    closeModal();

    const params = {
      _recordTypes:
        recordTypesFilter && !_.isEmpty(recordTypesFilter)
          ? recordTypesFilter.map((val) => val.key).join(';')
          : undefined,
      _solutionLevels:
        solutionLevelsFilter && !_.isEmpty(solutionLevelsFilter)
          ? solutionLevelsFilter.map((val) => val.key).join(';')
          : undefined,
      _operationsGroups:
        groupsFilter && !_.isEmpty(groupsFilter)
          ? groupsFilter.map((val) => val.key).join(';')
          : undefined,
      _testEvaluation: testEvaluation ? 'true' : undefined,
    };

    setFilterData({
      _recordTypes: params._recordTypes,
      _solutionLevels: params._solutionLevels,
      _operationsGroups: params._operationsGroups,
      _testEvaluation: params._testEvaluation,
    });
  }, [
    recordTypesFilter,
    solutionLevelsFilter,
    groupsFilter,
    setFilterData,
    testEvaluation,
    closeModal,
  ]);

  const cancelFilters = useCallback(() => {
    setRecordTypesFilter(
      query._recordTypes?.split(';')?.map((val) => ({ key: val, value: val }))
    );
    setSolutionLevelsFilter(
      query._solutionLevels?.split(';')?.map((val) => ({ key: val, value: val }))
    );
    setGroupsFilter(
      query._operationsGroups?.split(';')?.map((val) => ({ key: val, value: val }))
    );

    closeModal();
  }, [query, closeModal]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    await getOperationsGroups();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const groupsFiltered = operationsGroupsData?.docs?.filter((group) => group?.name);

    setAllGroups(groupsFiltered || []);
    setGroupsSearched(groupsFiltered || []);
  }, [operationsGroupsData]);

  useEffect(() => {
    if (recordTypesFilter?.find((recordType) => recordType?.value === 'documentscopy')) {
      setIsDocumentscopySelected(true);
    } else {
      setIsDocumentscopySelected(false);
      setSolutionLevelsFilter(undefined);
    }
  }, [recordTypesFilter]);

  const Modal = useMemo(
    () => (
      <StandardModal
        okText="Ok"
        okType="primary"
        visible={modalVisible}
        wrapClassName="queue-filters-modal-component"
        footer={null}
        centered
        closable
        maskClosable={false}
        onCancel={cancelFilters}
        keyboard
      >
        <div className="modal-header">
          <Title level={2} className="title">
            Definir filtros
          </Title>
          <Text className="subtitle">Defina os filtros de consulta na fila.</Text>
        </div>

        <div className="modal-body">
          <Row>
            <Col span={8}>
              <div className="filter-box">
                <Title level={3} className="title">
                  Tipos de registro
                </Title>
                <SelectCustomFilter
                  mode="multiple"
                  allowClear
                  value={recordTypesFilter}
                  setFilter={setRecordTypesFilter}
                  options={[
                    { key: 'documentscopy', label: 'Documentoscopia' },
                    { key: 'pending-ocr', label: 'Pendente OCR' },
                    { key: 'double-check-liveness', label: 'Double-check de Liveness' },
                  ]}
                  showSearch={false}
                />
              </div>
            </Col>
            <Col span={8}>
              <div
                className={`filter-box ${classNames({
                  'set-opacity': !isDocumentscopySelected,
                })}`}
              >
                <Title level={3} className="title">
                  Tipo de solução na Documentoscopia
                </Title>
                <SelectCustomFilter
                  mode="multiple"
                  allowClear
                  listHeight={264}
                  value={solutionLevelsFilter}
                  setFilter={setSolutionLevelsFilter}
                  options={[
                    { key: 'standard', label: 'Standard' },
                    { key: 'advanced', label: 'Advanced' },
                    { key: 'qrcode', label: 'QRCode' },
                    { key: 'international', label: 'International' },
                    { key: 'exclusive', label: 'Exclusive' },
                    { key: 'poc', label: 'POC' },
                    { key: 'premium', label: 'Premium' },
                    { key: 'quality', label: 'Quality' },
                  ]}
                  showSearch={false}
                  disabled={!isDocumentscopySelected}
                />
              </div>
            </Col>
            <Col span={8}>
              <div className="filter-box">
                <Title level={3} className="title">
                  Grupos de operações
                </Title>
                <SelectCustomFilter
                  mode="multiple"
                  allowClear
                  listHeight={264}
                  value={groupsFilter}
                  setFilter={setGroupsFilter}
                  options={groupsSearched.map((group) => ({
                    key: group.id,
                    value: group.name,
                    label: group.name,
                  }))}
                  showSearch
                  onSearch={onGroupSearch}
                  onClear={() => setGroupsSearched(allGroups)}
                  onBlur={() => {
                    setSearchGroup(null);
                    setGroupsSearched(allGroups);
                  }}
                  disabled={loadingOperationsGroups}
                  loading={loadingOperationsGroups}
                  notFoundContent={
                    !loadingOperationsGroups && !operationsGroupsData?.docs?.length ? (
                      <span>
                        {searchGroup
                          ? `Nenhum resultado encontrado para "${searchGroup}"`
                          : 'Nenhum resultado encontrado'}
                      </span>
                    ) : null
                  }
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Checkbox
                checked={testEvaluation}
                onChange={(e) => setTestEvaluation(e?.target?.checked)}
              >
                Análises de testes (Lista apenas os clientes em teste)
              </Checkbox>
            </Col>
          </Row>
        </div>

        <div className="modal-footer flex space-between">
          <Button type="danger" onClick={cancelFilters}>
            Cancelar
          </Button>
          <Button className="btn-ok btn-custom-primary" onClick={applyFilters}>
            Aplicar filtros
          </Button>
        </div>
      </StandardModal>
    ),
    [
      modalVisible,
      recordTypesFilter,
      solutionLevelsFilter,
      applyFilters,
      onGroupSearch,
      loadingOperationsGroups,
      allGroups,
      operationsGroupsData,
      groupsFilter,
      groupsSearched,
      searchGroup,
      isDocumentscopySelected,
      cancelFilters,
      testEvaluation,
    ]
  );

  return {
    openModal,
    closeModal,
    Modal,
  };
};

export default QueueFiltersModal;

import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { useQueryParams } from 'use-query-params';
import { Row, Col, Table } from 'antd';

import { useFetch } from 'services/hooks';

import ViewMore from 'components/ViewMore';
import showToastMessage from 'components/ToastMessage';

import { initialFilterData } from '../utils';

const useTable = ({
  rowKey,
  getParams,
  columns,
  queryParams,
  customLoading = false,
  rowClassName,
}) => {
  const { get, loading, setLoading } = useFetch();

  const [data, setData] = useState([]);
  const [displayedData, setDisplayedData] = useState([]);
  const batchSize = 100; // Número de itens por carregamento
  const [totals, setTotals] = useState({});
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(undefined);
  const tableContainerRef = useRef(null);

  const [params, setParams] = useState(getParams);
  const [query, setQuery] = useQueryParams({
    ...queryParams,
  });
  const [filterData, setFilterData] = useState(() =>
    initialFilterData({ query, queryParams })
  );

  const fetch = useCallback(
    async ({ loadMore }) => {
      try {
        if (!params) return;

        const { username, id } = lastEvaluatedKey || {};
        const requestParams =
          lastEvaluatedKey && loadMore
            ? { ...params.config.params, ...filterData, lastEvaluatedKey: username || id }
            : { ...params.config.params, ...filterData };

        const response = await get({
          url: params.url,
          config: {
            params: requestParams,
          },
          showMessage: false,
        });
        const {
          lastEvaluatedKey: respLastEvaluatedKey,
          docs,
          totals: totalItems,
        } = response || {};

        setLastEvaluatedKey(respLastEvaluatedKey);
        setTotals(totalItems);

        if (loadMore) {
          setData((oldState) => [...oldState, ...(docs || [])]); // Carregar mais
        } else {
          setData(docs || []); // Atualizar a lista com os novos dados
          setDisplayedData((docs || []).slice(0, batchSize)); // Resetar os dados exibidos
          if (tableContainerRef.current) tableContainerRef.current.scrollTop = 0; // Scroll para o topo
        }

        if (!loadMore && tableContainerRef.current) {
          tableContainerRef.current.scrollTop = 0; // Rola para o topo
        }
      } catch (err) {
        showToastMessage({
          type: 'error',
          text: 'Erro ao obter os dados da lista.',
        });
      }
    },
    [get, params, lastEvaluatedKey, filterData]
  );

  const refreshList = useCallback(() => {
    fetch({ loadMore: false });
  }, [fetch]);

  const updateParams = useCallback((newParams) => {
    setParams(newParams);
  }, []);

  useEffect(() => {
    if (!data?.length) return;
    fetch({ loadMore: false });
  }, [filterData, fetch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!data?.length) refreshList();
  }, [params, filterData, refreshList]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setQuery((prevQuery) => {
      const isDifferent = JSON.stringify(prevQuery) !== JSON.stringify(filterData);
      if (isDifferent) return { ...filterData };
      return prevQuery;
    });
  }, [filterData, setQuery]);

  const loadMoreData = useCallback(() => {
    const nextBatch = data.slice(displayedData.length, displayedData.length + batchSize);
    setDisplayedData((prevData) => [...prevData, ...nextBatch]);
  }, [data, displayedData, batchSize]);

  useEffect(() => {
    if (data.length) {
      setDisplayedData(data.slice(0, batchSize));
    }
  }, [data, batchSize]);

  // Detecta scroll no final da tabela
  const handleScroll = useCallback(
    (event) => {
      const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;

      if (scrollTop + clientHeight >= scrollHeight - 50) {
        loadMoreData();
      }
    },
    [loadMoreData]
  );

  const tableContent = useMemo(
    () =>
      columns && (
        <>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={24}>
              <div
                style={{ height: '400px', overflow: 'auto' }} // Define altura fixa com scroll
                onScroll={handleScroll} // Detecta o evento de scroll
                ref={tableContainerRef} // Vincula a ref aqui
              >
                <Table
                  columns={columns}
                  dataSource={displayedData || []}
                  loading={loading || customLoading}
                  pagination={false}
                  showSorterTooltip={false}
                  rowKey={rowKey}
                  className="custom-table-dark mrg-btm-30"
                  rowClassName={rowClassName}
                />
              </div>
            </Col>
          </Row>

          {lastEvaluatedKey && (
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={24}>
                <ViewMore
                  title={loading ? 'Carregando...' : 'Carregar mais'}
                  text={loading}
                  className="center"
                  loading={loading}
                  onClick={() => fetch({ loadMore: true })}
                />
              </Col>
            </Row>
          )}
        </>
      ),
    [
      columns,
      displayedData,
      handleScroll,
      loading,
      customLoading,
      rowKey,
      lastEvaluatedKey,
      fetch,
      rowClassName,
    ]
  );

  return {
    data,
    totals,
    tableContent,
    refreshList,
    updateParams,
    setFilterData,
    loading,
    setLoading,
    query,
  };
};

export default useTable;
